import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import LocationNav from "../components/locationNav";
import StoryExcerptList from "../components/storyExcerptList";

const filterStories = (edges, pageContext) => {
  edges.filter((edge) => {
    const { countryCode, stateCode } = pageContext;
    const node = edge.node;
    if (countryCode === "US") {
      return node.state === stateCode;
    }
  });
};

function storyBrowseTemplate({ data, pageContext }) {
  const countryCode = pageContext.countryCode;
  const stateFull = pageContext.stateFull;
  const countryFull = pageContext.countryFull;
  const countries = pageContext.allCountries;
  const stories = data.allContentfulStory.edges;
  console.log({ pageContext });
  return (
    <Layout>
      <LocationNav />

      <header className="font-bold text-2xl mt-8 mb-4">
        Stories from {stateFull || countryFull}
      </header>
      <StoryExcerptList stories={stories} />
    </Layout>
  );
}

export default storyBrowseTemplate;

export const pageQuery = graphql`
  query storyBrowseQuery($countryCode: String, $stateCode: String) {
    allContentfulStory(
      filter: { country: { eq: $countryCode }, state: { eq: $stateCode } }
    ) {
      edges {
        node {
          id
          excerpt
          countryFull
          stateFull
        }
      }
    }
  }
`;
