import React from "react";
import { Link } from "gatsby";

function StoryExcerptList({ stories }) {
  return (
    <ul>
      {stories.map((story) => (
        <li className="py-4 border-b" key={story.node.id}>
          <Link to={`/stories/${story.node.id}`}>{story.node.excerpt}</Link>
        </li>
      ))}
    </ul>
  );
}

export default StoryExcerptList;
